import * as React from "react"
import GatsbyImage, { GatsbyImageProps } from "gatsby-image"

type Props = GatsbyImageProps & {
  fluid: {
    presentationWidth: number
    presentationHeight: number
  }
  maxHeight: number
}
export const NonStretchedImage = (props: Props) => {
  let normalizedProps = props
  if (props.fluid && props.fluid.presentationWidth) {
    normalizedProps = {
      ...props,
      style: {
        ...(props.style || {}),
        // maxWidth: props.fluid.presentationWidth,
        maxHeight: props.maxHeight,
        maxWidth: (props.maxHeight / props.fluid.presentationHeight) * props.fluid.presentationWidth,
        margin: "0 auto", // Used to center the image
      },
    }
  }
  return <GatsbyImage {...normalizedProps} />
}
