import { Quiz } from "./types"
import * as TriviaStorage from "./TriviaStorage"
const scoreBands = [
  ["Novice", 0, 0.04, 0.02],
  ["Newbie", 0.04, 0.08, 0.08],
  ["Infant", 0.08, 0.12, 0.12],
  ["Child", 0.12, 0.16, 0.17],
  ["Beginner", 0.16, 0.2, 0.21],
  ["Amateur", 0.2, 0.24, 0.31],
  ["Rookie", 0.24, 0.28, 0.36],
  ["Hot Shot", 0.28, 0.32, 0.39],
  ["Whiz", 0.32, 0.36, 0.46],
  ["Semi-Pro", 0.36, 0.4, 0.51],
  ["Star", 0.4, 0.44, 0.57],
  ["Maestro", 0.44, 0.48, 0.62],
  ["Pro", 0.48, 0.52, 0.65],
  ["Superstar", 0.52, 0.56, 0.72],
  ["Prodigy", 0.56, 0.61, 0.74],
  ["Ninja", 0.61, 0.65, 0.77],
  ["Egghead", 0.65, 0.69, 0.79],
  ["Veteran", 0.69, 0.73, 0.82],
  ["Braniac", 0.74, 0.79, 0.86],
  ["Expert", 0.79, 0.83, 0.87],
  ["Genius", 0.83, 0.88, 0.89],
  ["Master", 0.88, 0.92, 0.92],
  ["Champion", 0.92, 0.96, 0.93],
  ["Legend", 0.96, 0.99, 0.96],
  ["Phenomenon", 0.99, 1.1, 0.99],
]
export const startQuiz = (slug: string) => {
  const newQuiz: Quiz = {
    slug,
    streak: 0,
    streakMax: 0,
    responses: [],
  }
  TriviaStorage.setQuiz(slug, newQuiz)
  return newQuiz
}

export const submitAnswer = (slug: string, questionIndex: number, correct: boolean) => {
  const quiz = TriviaStorage.getQuiz(slug) || startQuiz(slug)

  if (questionIndex === 0) {
    quiz.responses = []
  }

  quiz.responses[questionIndex] = correct
  TriviaStorage.setQuiz(slug, quiz)
  return quiz
}

export const wasAnswerCorrect = (slug: string, questionIndex: number) => {
  const quiz = TriviaStorage.getQuiz(slug) || startQuiz(slug)
  return quiz.responses[questionIndex] || false
}

export const increaseStreak = (slug: string) => {
  const quiz = TriviaStorage.getQuiz(slug) || startQuiz(slug)

  quiz.streak++

  if (quiz.streak > quiz.streakMax) {
    quiz.streakMax = quiz.streak
  }

  TriviaStorage.setQuiz(slug, quiz)
  return quiz
}

export const resetStreak = (slug: string) => {
  const quiz = TriviaStorage.getQuiz(slug) || startQuiz(slug)

  quiz.streak = 0

  TriviaStorage.setQuiz(slug, quiz)
  return quiz
}

export const getStatus = (slug: string) => {
  const quiz = TriviaStorage.getQuiz(slug) || startQuiz(slug)

  const totalAnswers = quiz.responses.length
  const correctAnswers = quiz.responses.filter(r => r).length
  const wrongAnswers = totalAnswers - correctAnswers
  let score = totalAnswers ? correctAnswers / totalAnswers : 0
  if (score < 0) {
    score = 0
  }
  if (score > 1) {
    score = 1
  }
  const prettyScore = `${Math.floor(score * 100)}%`
  const band = scoreBands.find(r => score >= r[1] && score < r[2])
  const rank = band ? (band[0] as string) : "-"
  const betterThan = band ? (band[3] as number) : 0
  const prettyBetterThan = `${Math.floor(betterThan * 100)}%`
  const streak = quiz.streak || 0
  const streakMax = quiz.streakMax || 0
  return {
    totalAnswers,
    correctAnswers,
    wrongAnswers,
    score,
    prettyScore,
    rank,
    streak,
    streakMax,
    betterThan,
    prettyBetterThan,
  }
}

export type QuizStatus = ReturnType<typeof getStatus>
