import * as React from "react"
import { SEO } from "../components/seo"
import { graphql, Link, withPrefix } from "gatsby"
import {
  Quiz,
  Quizzes_allQuizzesJson_edges_node_node,
  Quizzes_allQuizzesJson_edges_node_node_questionSet_edges_node,
  Quizzes_allQuizzesJson_edges_node_node_questionSet_edges_node_answerSet_edges_node,
  Quizzes_allQuizzesJson_edges_node_node_relatesTo,
  Layouts_allLayoutsJson_edges_node_node_variantSet_edges_node,
} from "../types/api"
import { SecondaryHeader } from "../components/header"
import { Footer } from "../components/Footer"
import GatsbyImage from "gatsby-image"
import { Ad, position, VendorInit, Wipe } from "../components/ad"
import { NonStretchedImage } from "../components/NonStretchedImage"
import * as Trivia from "../model/Trivia"
import { QuizStatus } from "../model/Trivia"
import { Omit } from "../model/types"
import * as TriviaStorage from "../model/TriviaStorage"
import { selectLayoutVariant, getLayout } from "../model/helpers"
import { BarLoader } from "react-spinners"

const overlay = require("../images/overlay-2.jpg")
const chevronLeft = require("../images/chevron-left-1.png")
const chevronRight = require("../images/chevron-right-1.png")
const chevronLeft1 = require("../images/chevron-left-2.png")
const chevronRight1 = require("../images/chevron-right-2.png")
const play = require("../images/play.png")

type Props = {
  pageContext: {
    id: string
    slug: string
    page: number
    totalPages: number
    quiz: Omit<Omit<Quizzes_allQuizzesJson_edges_node_node, "questionSet">, "relatesTo">
    question?: Quizzes_allQuizzesJson_edges_node_node_questionSet_edges_node
    previousQuestion?: Quizzes_allQuizzesJson_edges_node_node_questionSet_edges_node
    relatesTo?: Quizzes_allQuizzesJson_edges_node_node_relatesTo
  }
  location: any
  data: Quiz
}

type State = {
  preloadNext: boolean
  status: QuizStatus
  wasPreviousCorrect: boolean
  email: string
  adsLayout: Layouts_allLayoutsJson_edges_node_node_variantSet_edges_node
  loading: boolean
  loadingLoader: boolean
  first: boolean
  nextUrl: string
  answerExplainPartA: string
  answerExplainPartB: string
  answerExplainWords: number
  answerExplainParagraphs: any
  rightRail: boolean
}

export default class extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      preloadNext: false,
      status: null,
      wasPreviousCorrect: null,
      email: "",
      adsLayout: null,
      rightRail: true,
      loading: true,
      loadingLoader: false,
      first: true,
      nextUrl: "",
      answerExplainPartA: "",
      answerExplainPartB: "",
      answerExplainWords: 0,
      answerExplainParagraphs: [],
    }
  }

  componentDidMount() {
    if (TriviaStorage.getLoadDelayMax() > 0 && TriviaStorage.getLoadDelayMin() >= 0) {
      let body = document.getElementsByTagName("body")[0]
      body.style.overflow = "hidden"

      const sleep = (milliseconds: number) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
      }
      sleep(
        Math.floor(Math.random() * (TriviaStorage.getLoadDelayMax() - TriviaStorage.getLoadDelayMin())) +
          TriviaStorage.getLoadDelayMin()
      ).then(() => {
        this.setState({ loading: false })
        this.setState({ loadingLoader: false })
        body.style.overflow = "visible"
      })
    }

    const status = Trivia.getStatus(this.slug)
    const wasPreviousCorrect = Trivia.wasAnswerCorrect(this.slug, this.page - 1)
    this.setState({
      status,
      wasPreviousCorrect,
    })
    if (!this.questionImage) {
      this.preloadNext()
    }

    if (this.state.adsLayout === null) {
      getLayout().then(response => {
        this.setState({ adsLayout: selectLayoutVariant(response, this.props.pageContext.page + 2) })
        if (this.state.adsLayout) {
          TriviaStorage.setLoadDelayMin(this.state.adsLayout.loadDelayMin)
          TriviaStorage.setLoadDelayMax(this.state.adsLayout.loadDelayMax)

          let rightRailAd = false
          this.state.adsLayout.slotSet.edges.forEach(({ node: slot }) => {
            if (
              slot.slot === position.RIGHT_RAIL_UPPER ||
              slot.slot === position.RIGHT_RAIL_MIDDLE ||
              slot.slot === position.RIGHT_RAIL_LOWER
            ) {
              rightRailAd = true
            }
          })
          this.setState({ rightRail: rightRailAd })
        }
      })
    }

    if (
      this.lastQuestion &&
      TriviaStorage.getEntryConversion() == null &&
      (TriviaStorage.getEp() == null || TriviaStorage.getEp() > this.page + 2)
    ) {
      this.setState({ nextUrl: "entry/" })
    } else if (this.lastQuestion) {
      this.setState({ nextUrl: "score/" })
    } else {
      this.setState({ nextUrl: `page/${this.page + 3}/` })
    }

    // paragraph splitter

    let answerExplainPartA = ""
    let answerExplainPartB = ""
    let answerExplainWords = 0
    let answerExplainParagraphs = []
    if (this.props.pageContext.previousQuestion) {
      // Change <br /> to \n.
      let answerExplainReplace = this.props.pageContext.previousQuestion.answerExplain
      if (typeof this.props.pageContext.previousQuestion.answerExplain === "string") {
        answerExplainReplace = this.props.pageContext.previousQuestion.answerExplain.replace(/<br \/>/g, "\n")
      }

      if (typeof answerExplainReplace === "string") {
        answerExplainWords = answerExplainReplace.replace(/\n\n/g, " ").split(" ").length
      } else {
        answerExplainWords = answerExplainReplace.split(" ").length
      }
      answerExplainParagraphs = answerExplainReplace.split("\n\n")

      // Split one paragraph.
      if (answerExplainWords >= 200 && answerExplainParagraphs.length === 1) {
        let words = answerExplainReplace.split(" ")
        if (typeof answerExplainReplace === "string") {
          words = answerExplainReplace.replace(/\n\n/g, " ").split(" ")
        }
        let newAnswerExplainParagraphs = ""
        let half = words.length / 2
        let stopSearching = false
        for (let i = 0; i < words.length; i++) {
          if (stopSearching || i < half) {
            newAnswerExplainParagraphs += words[i] + " "
            continue
          }
          if (words[i].includes(".") || words[i].includes("?") || words[i].includes("!")) {
            newAnswerExplainParagraphs += words[i] + "\n\n"
            stopSearching = true
            continue
          } else {
            newAnswerExplainParagraphs += words[i] + " "
            continue
          }
        }
        answerExplainParagraphs = newAnswerExplainParagraphs.split("\n\n")
      }
    }
    this.setState({ answerExplainWords: answerExplainWords })
    this.setState({ answerExplainParagraphs: answerExplainParagraphs })
    if (answerExplainWords >= 200 && answerExplainParagraphs.length > 1) {
      let middleIndex = 0
      let countWords = 0
      for (let i = 0; i < answerExplainParagraphs.length; i++) {
        let wordsLength = answerExplainParagraphs[i].split(" ").length
        if (countWords + wordsLength > answerExplainWords / 2) {
          if (answerExplainWords / 2 - countWords < countWords + wordsLength - answerExplainWords / 2) {
            // paragraph before middle is the closest
            middleIndex = i - 1
          } else {
            // paragraph after middle is the closest
            middleIndex = i
          }
          break
        }
        countWords += wordsLength
      }
      for (let i = 0; i < answerExplainParagraphs.length; i++) {
        if (answerExplainParagraphs[i] === "") {
          continue
        }
        if (middleIndex >= i) {
          answerExplainPartA += (answerExplainPartA === "" ? "" : "<br /><br />") + answerExplainParagraphs[i]
        } else {
          answerExplainPartB += (answerExplainPartB === "" ? "" : "<br /><br />") + answerExplainParagraphs[i]
        }
      }
      this.setState({ answerExplainPartA: answerExplainPartA })
      this.setState({ answerExplainPartB: answerExplainPartB })
    }
  }

  render() {
    TriviaStorage.saveUtmFromLocation(this.props.location)
    if (
      (TriviaStorage.getLoadDelayMax() === 0 || TriviaStorage.getLoadDelayMax() === null) &&
      this.state.first === true
    ) {
      this.setState({ loading: false })
    }
    if (TriviaStorage.getLoadDelayMax() > 0 && this.state.first === true) {
      this.setState({ loadingLoader: true })
    }
    if (this.state.first === true) {
      this.setState({ first: false })
    }
    let metaDescription = "Quiz: " + this.quiz.title
    if (this.quiz.description) {
      metaDescription = this.quiz.description.substring(0, 290) + " ..."
    }

    let answers = this.question.answerSet.edges.map(({ node: answer }) => <span>{answer.answer}</span>)
    let flip = this.getFlip(this.question.id)

    return (
      <>
        <SEO
          title={`Quiz: ${this.quiz.title}`}
          isQuiz={true}
          description={metaDescription}
          url={`${this.props.pageContext.slug}/page/${this.page + 2}`}
          img={this.questionImage}
        />
        <VendorInit position="vendorInit" layout={this.state.adsLayout} />
        <SecondaryHeader />
        <div className="answer-modal hide-it" id="answer-modal" />
        <div id="page-level-tests" />
        <div className="game-parent">
          <div className="container">
            <div className="row">
              <BarLoader
                css={"position:absolute;top:300px;width:20%;left:40%;"}
                width={200}
                height={4}
                color={"#15716C"}
                loading={this.state.loadingLoader}
              />
              <div
                className="game-main-body"
                style={{
                  opacity: this.state.loading ? 0 : 1,
                  float: this.state.rightRail ? "left" : "none",
                  margin: this.state.rightRail ? 0 : "auto",
                }}
              >
                {this.hasScoreView ? this.renderScoreView() : null}
                {this.renderQuestionHeader(this.getFlip(this.question.id))}
                {this.questionImage && this.renderQuestionImage()}
                {this.answerImages.edges.length > 0 && this.renderAnswerImages(this.getFlip(this.question.id))}

                <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                  <div id="ABOVE_BUTTONS">
                    <Ad position={position.ABOVE_BUTTONS} layout={this.state.adsLayout} />
                  </div>
                  {this.renderAnswerButtonsOutline(this.getFlip(this.question.id))}

                  <div className="row">
                    <div style={{ padding: "0 15px" }}>
                      <div id="BELOW_BUTTONS_1">
                        <Ad position={position.BELOW_BUTTONS_1} layout={this.state.adsLayout} />
                      </div>
                      <div id="BELOW_BUTTONS_2">
                        <Ad position={position.BELOW_BUTTONS_2} layout={this.state.adsLayout} />
                      </div>
                      <div id="BELOW_BUTTONS_3">
                        <Ad position={position.BELOW_BUTTONS_3} layout={this.state.adsLayout} />
                      </div>
                    </div>
                  </div>

                  {this.moreFromTriviaBoss()}

                  <div className="row">
                    <div className="col-sm-12" id="question-sub-text" style={{ paddingTop: "15px" }}>
                      <div className="question-sub-text-button">
                        <div className="start-quiz-button-wrap">
                          <div
                            className="chevron"
                            style={{ backgroundImage: "url(" + chevronRight + ")", backgroundPosition: "left" }}
                          ></div>
                          <div className="question-sub-text-button-text">
                            Is it {answers[flip]} or {answers[1 - flip]}?
                          </div>
                          <div
                            className="chevron"
                            style={{ backgroundImage: "url(" + chevronLeft + ")", backgroundPosition: "right" }}
                          ></div>
                        </div>
                        {this.renderAnswerButtons(this.getFlip(this.question.id))}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div style={{ padding: "0 15px" }}>
                      <div id="BELOW_SECOND_BUTTONS_1">
                        <Ad position={position.BELOW_SECOND_BUTTONS_1} layout={this.state.adsLayout} />
                      </div>
                      <div id="BELOW_SECOND_BUTTONS_2">
                        <Ad position={position.BELOW_SECOND_BUTTONS_2} layout={this.state.adsLayout} />
                      </div>
                      <div id="BELOW_SECOND_BUTTONS_3">
                        <Ad position={position.BELOW_SECOND_BUTTONS_3} layout={this.state.adsLayout} />
                      </div>
                    </div>
                  </div>

                  {this.renderAuthorBio()}

                  <div className="pad-bottom-150" />
                </div>
              </div>
              {this.state.rightRail ? this.renderRightRail() : null}
            </div>
          </div>
        </div>
        <Footer slug={this.slug} />
        <div id="PAGE_LEVEL_1">
          <Ad position={position.PAGE_LEVEL_1} layout={this.state.adsLayout} />
        </div>
        <div id="PAGE_LEVEL_2">
          <Ad position={position.PAGE_LEVEL_2} layout={this.state.adsLayout} />
        </div>
        <div id="PAGE_LEVEL_3">
          <Ad position={position.PAGE_LEVEL_3} layout={this.state.adsLayout} />
        </div>
        <Wipe position="wipe" layout={this.state.adsLayout} />
      </>
    )
  }

  private renderAuthorBio() {
    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="row author-dash">
            <div className="col-md-12 text-center">
              <div className="quiz-by">Quiz Written By</div>
            </div>
          </div>

          <div className="row pad-top-15">
            <div className="col-xs-12">
              {this.props.data.authorImage ? (
                <GatsbyImage fluid={this.props.data.authorImage.childImageSharp.fluid} className="author-image-large" />
              ) : null}
              <div className="author-name-large">
                {[this.quiz.author.firstName, this.quiz.author.lastName].filter(e => e.length > 0).join(" ")}
                ,<br /> <span className="author-title">Quizmaster</span>
              </div>
            </div>
          </div>

          <div className="row pad-top-10">
            <div className="col-md-12 author-desc">{this.quiz.author.description}</div>
          </div>
        </div>
      </div>
    )
  }

  private moreFromTriviaBoss() {
    return (
      <div className="row more-from-trivia">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="more-from-trivia-title-wrap">
                <div
                  className="chevron"
                  style={{ backgroundImage: "url(" + chevronRight1 + ")", backgroundPosition: "left" }}
                ></div>
                <div className="more-from-trivia-quiz-by">More from TriviaBOSS</div>
                <div
                  className="chevron"
                  style={{ backgroundImage: "url(" + chevronLeft1 + ")", backgroundPosition: "right" }}
                ></div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 summary">
            <div className="row">
              {this.props.pageContext.questionRelatedQuizzes.edges.map(({ node }) => {
                const image = this.questionRelatedQuizzesImages.find(n => n.relativePath === node.featuredImage)
                return (
                  <div key={node.id}>
                    {image ? (
                      <div className="row">
                        <div className="col-md-12">
                          <div className="recommended-next-quiz-wrapper">
                            <GatsbyImage fluid={image.childImageSharp.fluid} className="recommended-next-quiz" />
                            <div className="recommended-next-quiz-headline-wrapper">
                              <div className="recommended-next-quiz-headline">
                                <div className="next-quiz-content">
                                  <p className="next-quiz-title">{node.title}</p>
                                  <p
                                    className="next-quiz-description"
                                    dangerouslySetInnerHTML={{ __html: node.description.substring(0, 320) + " ..." }}
                                  ></p>
                                </div>
                                <div className="recommended-next-quiz-button">
                                  <a
                                    href={process.env.GATSBY_URL_PREFIX + `/${node.slug}/`}
                                    className="btn btn-primary start-quiz-button"
                                  >
                                    <div className="start-quiz-button-play">
                                      <div style={{ backgroundImage: "url(" + play + ")" }}></div>
                                    </div>
                                    <span>Start</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }

  private renderAnswerButtons(flip: number) {
    let answers = this.question.answerSet.edges.map(({ node: answer }) => (
      <div className="col-sm-6" key={answer.id} style={{ zIndex: 11111 }}>
        <a
          href={process.env.GATSBY_URL_PREFIX + `/${this.props.pageContext.slug}/${this.state.nextUrl}`}
          onClick={() => this.onAnswer(answer)}
          className="btn btn-block btn-lg btn-primary button-listicle game-button"
        >
          {answer.answer}
        </a>
      </div>
    ))

    return (
      <div className="row button-mobile" id="answer-buttons" style={{ paddingBottom: "10px" }}>
        {answers[flip]}
        {answers[1 - flip]}
      </div>
    )
  }

  private renderAnswerButtonsOutline(flip: number) {
    let answers = this.question.answerSet.edges.map(({ node: answer }) => (
      <div className="col-sm-6" key={answer.id} style={{ zIndex: 11111 }}>
        <a
          href={process.env.GATSBY_URL_PREFIX + `/${this.props.pageContext.slug}/${this.state.nextUrl}`}
          onClick={() => this.onAnswer(answer)}
          className="btn btn-block btn-lg btn-primary button-listicle game-button-outline"
        >
          {answer.answer}
        </a>
      </div>
    ))

    return (
      <div className="row button-mobile button-mobile-outline" id="answer-buttons">
        {answers[flip]}
        {answers[1 - flip]}
      </div>
    )
  }

  private renderQuestionImage() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-xs-12 pad-bottom-5">
            <div
              className="game-image-full-background"
              id="single"
              style={{
                backgroundImage: this.questionImage ? `url(${this.questionImage.base64})` : null,
              }}
            >
              <div className="game-image-full-overlay">
                {this.state.preloadNext && this.nextQuestionImage && (
                  <div style={{ width: 0, height: 0, overflow: "hidden !important", visibility: "hidden" }}>
                    <NonStretchedImage fluid={this.nextQuestionImage} maxHeight={400} loading="eager" />
                  </div>
                )}
                {this.questionImage && (
                  <NonStretchedImage
                    fluid={this.questionImage}
                    maxHeight={400}
                    className="game-image-full"
                    onLoad={this.preloadNext}
                    loading="eager"
                    fadeIn={false}
                  />
                )}
              </div>
            </div>
            <span style={{ fontSize: "12px " }}>Getty Images</span>
          </div>
        </div>
      </div>
    )
  }

  private renderAnswerImages(flip: number) {
    let answerImage = this.question.answerSet.edges.map(({ node: answer }, i) => (
      <div
        key={answer.id}
        className="col-xs-6 pad-bottom-5"
        style={i == 0 ? { paddingRight: "0" } : { paddingLeft: "0" }}
      >
        <div className="game-image-full-background" id="single">
          <div className="game-image-full-overlay">
            {this.state.preloadNext && this.nextAnswerImages && this.nextAnswerImages.edges[i] && (
              <div style={{ width: 0, height: 0, overflow: "hidden !important", visibility: "hidden" }}>
                <NonStretchedImage
                  fluid={this.nextAnswerImages.edges[i].node.childImageSharp.fluid}
                  maxHeight={400}
                  loading="eager"
                />
              </div>
            )}
            {answer.image && (
              <NonStretchedImage
                fluid={this.answerImage(answer.image)}
                maxHeight={400}
                style={{ height: "400px" }}
                className="game-image-full"
                onLoad={this.preloadNext}
                loading="eager"
                fadeIn={false}
              />
            )}
          </div>
        </div>
      </div>
    ))

    return (
      <div className="container">
        <div className="row">
          {answerImage[flip]}
          {answerImage[1 - flip]}
        </div>
        <span style={{ fontSize: "12px " }}>Getty Images</span>
      </div>
    )
  }

  private renderQuestionHeader(flip: number) {
    let answers = this.question.answerSet.edges.map(({ node: answer }) => <span>{answer.answer}</span>)

    return (
      <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
        <div className="row">
          <div className="col-sm-12">
            {this.page > 0 && (
              <div className="fun-fact-wrapper">
                <div className="fun-fact-header">Did you know ...</div>
                <div className="fun-fact-bubble">?!</div>
                <div className="fun-fact">
                  <span className="fun-fact-title">{this.question.funFactTitle}</span>
                  <br />
                  <br />
                  <span className="fun-fact-text">{this.question.funFactText}</span>
                </div>
              </div>
            )}
            <div id="ABOVE_HEADLINE">
              <Ad position={position.ABOVE_HEADLINE} layout={this.state.adsLayout} />
            </div>
            <h4 className="game-question" id="game-question">
              {this.question.question}
            </h4>
            <div id="BELOW_HEADLINE">
              <Ad position={position.BELOW_HEADLINE} layout={this.state.adsLayout} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 question-sub-text" id="question-sub-text">
            Is it {answers[flip]} or {answers[1 - flip]}?
          </div>
        </div>
      </div>
    )
  }

  private renderScoreView = () => {
    // const question = quiz.questionSet.edges.find(e => e.node.order == page).node
    const correctAnswerEdge = this.previousQuestion.answerSet.edges.filter(e => e.node.correct)[0]
    const correctAnswer = correctAnswerEdge ? correctAnswerEdge.node.answer : null

    let answerColor = ""
    if (this.state.wasPreviousCorrect) {
      answerColor = "game-color-green-transparent"
    }
    if (this.state.wasPreviousCorrect === false) {
      answerColor = "game-color-red-transparent"
    }

    return (
      <div className="game-answer-underlay" id="answer-card" style={{ backgroundImage: "url(" + overlay + ")" }}>
        <div id="the-answer-wrapper" className={`answer-wrapper ${answerColor}`}>
          <div className="row">
            <div className="col-md-12 no-padding">
              <div className="score-section-include">
                <span className="score-label">Score:</span>{" "}
                <span className="score-value" id="real-score">
                  {this.state.status ? this.state.status.prettyScore : ""}
                </span>
                <span className="score-label-right">
                  <span className="score-label">Rank:</span>{" "}
                  <span className="score-value" id="score-rank">
                    {this.state.status ? this.state.status.rank : ""}
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 no-padding">
              <div className="game-answer" id="game-answer-wrapper">
                <div id="BELOW_SCORE">
                  <Ad position={position.BELOW_SCORE} layout={this.state.adsLayout} />
                </div>
                <div id="animatepushup" />

                <h3
                  className="game-correct-text"
                  id="answer-verification"
                  style={{ display: this.state.loading ? "none" : "block" }}
                >
                  {this.state.wasPreviousCorrect && (
                    <div className="good-answer">
                      Nailed it!
                      <br />
                      Keep going!
                    </div>
                  )}
                  {this.state.wasPreviousCorrect === false && (
                    <div className="bad-answer">
                      Not Quite!
                      <br />
                      Try Another!
                    </div>
                  )}
                </h3>
                <div id="BELOW_RESPONSE">
                  <Ad position={position.BELOW_RESPONSE} layout={this.state.adsLayout} />
                </div>
                <div id="ABOVE_CORRECT_ANSWER">
                  <Ad position={position.ABOVE_CORRECT_ANSWER} layout={this.state.adsLayout} />
                </div>
                <h3 className="game-last-answer">Correct Answer: {correctAnswer}</h3>
                <div id="ABOVE_ANSWER_PIC">
                  <Ad position={position.ABOVE_ANSWER_PIC} layout={this.state.adsLayout} />
                </div>
                {this.previousQuestionExplainImage && this.renderExplainImage()}
                {!this.previousQuestionExplainImage && this.previousQuestionImage && this.renderPreviousQuestionImage()}
                {!!this.previousAnswerImages.edges.length && this.renderPreviousAnswerImages()}
                <div id="BELOW_ANSWER_PIC">
                  <Ad position={position.BELOW_ANSWER_PIC} layout={this.state.adsLayout} />
                </div>
                {this.state.answerExplainWords >= 200 &&
                  this.state.answerExplainParagraphs.length > 1 &&
                  this.renderAnswerExplainWithParagraphSplitter()}
                {(this.state.answerExplainWords <= 200 || this.state.answerExplainParagraphs.length <= 1) && (
                  <h4
                    className="game-answer-explain"
                    dangerouslySetInnerHTML={{ __html: this.previousQuestion.answerExplain }}
                  />
                )}
                <div id="BELOW_CORRECT_ANSWER">
                  <Ad position={position.BELOW_CORRECT_ANSWER} layout={this.state.adsLayout} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  private renderAnswerExplainWithParagraphSplitter = () => {
    return (
      <div>
        <h4
          className="game-answer-explain"
          dangerouslySetInnerHTML={{
            __html: this.state.answerExplainPartA,
          }}
        />
        <div id="PARAGRAPH_SPLITTER">
          <Ad position={position.PARAGRAPH_SPLITTER} layout={this.state.adsLayout} />
        </div>
        <h4
          className="game-answer-explain"
          dangerouslySetInnerHTML={{
            __html: this.state.answerExplainPartB,
          }}
        />
      </div>
    )
  }

  private renderPreviousAnswerImages = () => {
    let answerImage = this.previousQuestion.answerSet.edges.filter(({ node: answer }) => {
      return answer.correct
    })

    return (
      <div className="pad-top-15">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 pad-bottom-5">
              <div className="game-image-full-background">
                <div className="game-image-full-overlay">
                  {answerImage[0] && (
                    <NonStretchedImage
                      fluid={this.previousAnswerImage(answerImage[0].node.image)}
                      maxHeight={400}
                      style={{ height: "400px" }}
                      className="game-image-full"
                      onLoad={this.preloadNext}
                      loading="eager"
                      fadeIn={false}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  private renderPreviousQuestionImage = () => {
    return (
      <div className="pad-top-15">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 pad-bottom-5">
              <div className="game-image-full-background">
                <div className="game-image-full-overlay">
                  {this.previousQuestionImage && (
                    <NonStretchedImage
                      fluid={this.previousQuestionImage}
                      style={{ height: "400px" }}
                      maxHeight={400}
                      className="game-image-full"
                      onLoad={this.preloadNext}
                      loading="eager"
                      fadeIn={false}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  private renderExplainImage = () => {
    return (
      <div className="pad-top-15">
        <div className="container">
          <div className="row">
            <div className="col-xs-6 pad-bottom-5" style={{ paddingRight: "0px" }}>
              <div className="game-image-full-background" id="single">
                <div className="game-image-full-overlay">
                  {this.previousQuestionImage && (
                    <NonStretchedImage
                      fluid={this.previousQuestionImage}
                      style={{ height: "400px" }}
                      maxHeight={400}
                      className="game-image-full"
                      onLoad={this.preloadNext}
                      loading="eager"
                      fadeIn={false}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-xs-6 pad-bottom-5" style={{ paddingLeft: "0px" }}>
              <div className="game-image-full-background" id="single">
                <div className="game-image-full-overlay">
                  {this.state.preloadNext && this.explainImage && (
                    <div style={{ width: 0, height: 0, overflow: "hidden !important", visibility: "hidden" }}>
                      <NonStretchedImage fluid={this.explainImage} maxHeight={400} loading="eager" />
                    </div>
                  )}
                  {this.previousQuestionExplainImage && (
                    <NonStretchedImage
                      fluid={this.previousQuestionExplainImage}
                      style={{ height: "400px" }}
                      maxHeight={400}
                      className="game-image-full"
                      onLoad={this.preloadNext}
                      loading="eager"
                      fadeIn={false}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  private renderRightRail = () => {
    return (
      <div className="game-rail sticky-sidebar right-rail" id="right-rail">
        <span className="game-right-rail-ad-label">Advertisements</span>
        <div id="RIGHT_RAIL_UPPER">
          <Ad position={position.RIGHT_RAIL_UPPER} layout={this.state.adsLayout} />
        </div>
        <div id="RIGHT_RAIL_MIDDLE">
          <Ad position={position.RIGHT_RAIL_MIDDLE} layout={this.state.adsLayout} />
        </div>
        <div id="RIGHT_RAIL_LOWER">
          <Ad position={position.RIGHT_RAIL_LOWER} layout={this.state.adsLayout} />
        </div>
      </div>
    )
  }

  private get questionImage() {
    return this.props.data.questionImage ? this.props.data.questionImage.childImageSharp.fluid : null
  }

  private get nextQuestionImage() {
    return this.props.data.nextQuestionImage ? this.props.data.nextQuestionImage.childImageSharp.fluid : null
  }

  private get previousQuestionImage() {
    return this.props.data.previousQuestionImage ? this.props.data.previousQuestionImage.childImageSharp.fluid : null
  }

  private get previousQuestionExplainImage() {
    return this.props.data.previousQuestionExplainImage
      ? this.props.data.previousQuestionExplainImage.childImageSharp.fluid
      : null
  }

  private get explainImage() {
    return this.props.data.explainImage ? this.props.data.explainImage.childImageSharp.fluid : null
  }

  private get answerImages() {
    return this.props.data.answerImages ? this.props.data.answerImages : null
  }

  private get previousAnswerImages() {
    return this.props.data.previousAnswerImages ? this.props.data.previousAnswerImages : null
  }

  private get nextAnswerImages() {
    return this.props.data.nextAnswerImages ? this.props.data.nextAnswerImages : null
  }

  private get slug() {
    return this.props.pageContext.slug
  }

  private get lastQuestion() {
    return this.props.pageContext.lastQuestion
  }

  private get page() {
    return this.props.pageContext.page
  }

  private get totalPages() {
    return this.props.pageContext.totalPages
  }

  private get quiz() {
    return this.props.pageContext.quiz
  }

  private get question() {
    return this.props.pageContext.question
  }

  private get previousQuestion() {
    return this.props.pageContext.previousQuestion
  }

  private get hasScoreView() {
    return this.page > 0 && this.page < this.totalPages
  }

  private answerImage = (imagePath: String) => {
    return this.answerImages.edges.filter(e => e.node.relativePath == imagePath)[0].node.childImageSharp.fluid
  }

  private previousAnswerImage = (imagePath: String) => {
    return this.previousAnswerImages.edges.filter(e => e.node.relativePath == imagePath)[0].node.childImageSharp.fluid
  }

  private onAnswer = (answer: Quizzes_allQuizzesJson_edges_node_node_questionSet_edges_node_answerSet_edges_node) => {
    Trivia.submitAnswer(this.slug, this.page, answer.correct)
    answer.correct ? Trivia.increaseStreak(this.slug) : Trivia.resetStreak(this.slug)
  }

  private preloadNext = () => {
    this.setState({ preloadNext: true })
  }

  private get questionRelatedQuizzesImages() {
    return this.props.data.questionRelatedQuizzesImages.edges.map(e => e.node)
  }

  private getFlip(seed: string) {
    if (seed) {
      let x = 0
      for (let i = 0; i < seed.length; i++) {
        x += seed.charCodeAt(i)
      }
      return x % 2
    }
    return 0
  }
}

export const query = graphql`
  query Quiz(
    $questionImage: String
    $authorImage: String
    $nextQuestionImage: String
    $previousQuestionImage: String
    $previousQuestionExplainImage: String
    $explainImage: String
    $answerImages: [String]
    $previousAnswerImages: [String]
    $nextAnswerImages: [String]
    $questionRelatedQuizzesImages: [String]
    $hasQuestionRelatedQuizzesImages: Boolean!
  ) {
    questionRelatedQuizzesImages: allFile(filter: { relativePath: { in: $questionRelatedQuizzesImages } })
      @include(if: $hasQuestionRelatedQuizzesImages) {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
    questionImage: file(relativePath: { eq: $questionImage }) {
      relativePath
      name
      childImageSharp {
        fluid(maxHeight: 400) {
          base64
          aspectRatio
          src
          srcSet
          sizes
          presentationWidth
          presentationHeight
        }
      }
    }
    explainImage: file(relativePath: { eq: $explainImage }) {
      relativePath
      name
      childImageSharp {
        fluid(maxHeight: 400) {
          base64
          aspectRatio
          src
          srcSet
          sizes
          presentationWidth
          presentationHeight
        }
      }
    }
    nextQuestionImage: file(relativePath: { eq: $nextQuestionImage }) {
      relativePath
      name
      childImageSharp {
        fluid(maxHeight: 400) {
          base64
          aspectRatio
          src
          srcSet
          sizes
          presentationWidth
          presentationHeight
        }
      }
    }
    previousQuestionImage: file(relativePath: { eq: $previousQuestionImage }) {
      relativePath
      name
      childImageSharp {
        fluid(maxHeight: 400) {
          base64
          aspectRatio
          src
          srcSet
          sizes
          presentationWidth
          presentationHeight
        }
      }
    }
    previousQuestionExplainImage: file(relativePath: { eq: $previousQuestionExplainImage }) {
      relativePath
      name
      childImageSharp {
        fluid(maxHeight: 400) {
          base64
          aspectRatio
          src
          srcSet
          sizes
          presentationWidth
          presentationHeight
        }
      }
    }

    authorImage: file(relativePath: { eq: $authorImage }) {
      relativePath
      name
      childImageSharp {
        fluid(maxHeight: 100) {
          base64
          aspectRatio
          src
          srcSet
          sizes
          presentationWidth
          presentationHeight
        }
      }
    }

    previousAnswerImages: allFile(filter: { relativePath: { in: $previousAnswerImages } }) {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            fluid(maxHeight: 400) {
              base64
              aspectRatio
              src
              srcSet
              sizes
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    }
    answerImages: allFile(filter: { relativePath: { in: $answerImages } }) {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            fluid(maxHeight: 400) {
              base64
              aspectRatio
              src
              srcSet
              sizes
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    }
    nextAnswerImages: allFile(filter: { relativePath: { in: $nextAnswerImages } }) {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            fluid(maxHeight: 400) {
              base64
              aspectRatio
              src
              srcSet
              sizes
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    }
  }
`
